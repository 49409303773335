import React from 'react';

export const facebookIcon = () => {
  return (
    <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="22.5" cy="23" rx="22.5" ry="23" fill="#618741" />
      <path d="M24.3332 26.1875H27.2498L28.4165 21.6875H24.3332V19.4375C24.3332 18.2787 24.3332 17.1875 26.6665 17.1875H28.4165V13.4075C28.0362 13.3591 26.6 13.25 25.0833 13.25C21.9158 13.25 19.6665 15.1141 19.6665 18.5375V21.6875H16.1665V26.1875H19.6665V35.75H24.3332V26.1875Z" fill="white" />
    </svg>

  );
};
