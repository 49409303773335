import React from 'react';

export const linkedinIcon = () => {
  return (
    <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="22.5" cy="23" rx="22.5" ry="23" fill="#618741" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5 14.2083C14.5 13.0577 15.3954 12.125 16.5 12.125C17.0304 12.125 17.5391 12.3445 17.9142 12.7352C18.2893 13.1259 18.5 13.6558 18.5 14.2083C18.5 15.3589 17.6046 16.2917 16.5 16.2917C15.3954 16.2917 14.5 15.3589 14.5 14.2083ZM32 22.9688C32.0345 20.7134 30.5574 18.7371 28.45 18.2187C27.0377 17.902 25.5657 18.3174 24.5 19.3333V18.8958C24.5 18.6082 24.2761 18.375 24 18.375H21.5C21.2239 18.375 21 18.6082 21 18.8958V29.3125C21 29.6001 21.2239 29.8333 21.5 29.8333H24C24.2761 29.8333 24.5 29.6001 24.5 29.3125V23.4375C24.4753 22.3914 25.1753 21.4778 26.16 21.2708C26.7464 21.1654 27.3473 21.3374 27.7997 21.7401C28.252 22.1428 28.5087 22.7344 28.5 23.3542V29.3125C28.5 29.6001 28.7239 29.8333 29 29.8333H31.5C31.7761 29.8333 32 29.6001 32 29.3125V22.9688ZM18.5 18.8958V29.3125C18.5 29.6001 18.2761 29.8333 18 29.8333H15.5C15.2239 29.8333 15 29.6001 15 29.3125V18.8958C15 18.6082 15.2239 18.375 15.5 18.375H18C18.2761 18.375 18.5 18.6082 18.5 18.8958Z" fill="white" />
    </svg>

  );
};
