import React, { useEffect, useRef, useState } from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockFooter.module.css';

const BlockFooter = props => {
  const { blockId, className, rootClassName, textClassName, text, options } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([text], options);
  const textRefs = useRef([]);
  const imageRefs = useRef([]); // Separate ref for images
  const [hasLoaded, setHasLoaded] = useState(false);

  const isInView = (el, threshold = 0.25) => {
    const rect = el.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const elementHeight = rect.bottom - rect.top;

    // Check if the element is at least "threshold" portion in view
    const inViewHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
    const visibilityRatio = inViewHeight / elementHeight;

    return visibilityRatio > threshold;
  };

  const handleScroll = () => {
    if (!hasLoaded) return;

    textRefs.current.forEach((el) => {
      if (el && isInView(el)) {
        if (!el.classList.contains(css.inView)) {
          el.classList.add(css.inView);
        }
      }
    });

    imageRefs.current.forEach((el, index) => {
      if (el && isInView(el)) {
        // const animationClass = blockId === "why_choose_ankyr-block-1" ? css.inViewLeft : css.inViewRight; 
        if (!el.classList.contains(css.inViewPosition)) {
          el.classList.add(css.inViewPosition);
        }
      }
    });
  };

  useEffect(() => {
    setHasLoaded(true);
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger once on load

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasLoaded]);
  return (
    <BlockContainer id={blockId} className={classes}>
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <div ref={(el) => (textRefs.current[0] = el)} className={css.textContent}>
            <Field data={text} options={options} />
          </div>
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  text: null,
  options: null,
};

BlockFooter.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  textClassName: string,
  text: object,
  options: propTypeOption,
};

export default BlockFooter;
