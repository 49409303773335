import React, { useEffect, useRef, useState } from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
  const textRefs = useRef([]);
  const imageRefs = useRef([]); // Separate ref for images
  const [hasLoaded, setHasLoaded] = useState(false);

  const isInView = (el, threshold = 0.25) => {
    const rect = el.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const elementHeight = rect.bottom - rect.top;

    // Check if the element is at least "threshold" portion in view
    const inViewHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
    const visibilityRatio = inViewHeight / elementHeight;

    return visibilityRatio > threshold;
  };

  const handleScroll = () => {
    if (!hasLoaded) return;

    textRefs.current.forEach((el) => {
      if (el && isInView(el)) {
        if (!el.classList.contains(css.inView)) {
          el.classList.add(css.inView);
        }
      }
    });

    imageRefs.current.forEach((el, index) => {
      if (el && isInView(el)) {
        // const animationClass = blockId === "why_choose_ankyr-block-1" ? css.inViewLeft : css.inViewRight; 
        if (!el.classList.contains(css.inViewPosition)) {
          el.classList.add(css.inViewPosition);
        }
      }
    });
  };

  useEffect(() => {
    setHasLoaded(true);
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger once on load

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasLoaded]);


  return (
    <BlockContainer id={blockId} className={classes}>
      <div
        className={classNames(css.imageCard,
          blockId === "our_story_section-block-2" ||
            blockId === "challenge_traditional_industry-block-2" ||
            blockId === "take_control_section-block-2" ? css.imageCardRight
            : blockId === "hero_section-block-1" ? css.imageCardTop
            : blockId === "how_it_works-block-1" ? css.noAnimation
            : blockId === "how_it_works-block-2" ? css.noAnimation
            : blockId === "how_it_works-block-3" ? css.noAnimation
            : blockId === "how_it_works-block-4" ? css.noAnimation
            : blockId === "meet_realtors-block-1" ? css.noAnimation
            : blockId === "meet_realtors-block-2" ? css.noAnimation
            : blockId === "meet_realtors-block-3" ? css.noAnimation
              : null)} ref={(el) => (imageRefs.current[0] = el)}>
        <FieldMedia
          media={media}
          sizes={responsiveImageSizes}
          className={mediaClassName}
          options={options}
        />
      </div>

      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text, css.container)}>
          <div ref={(el) => (textRefs.current[0] = el)} className={css.textContent}>
            <Field data={title} options={options} />
          </div>
          <div ref={(el) => (textRefs.current[1] = el)} className={css.textContent}>
            <Field data={text} options={options} />
          </div>
          <div ref={(el) => (textRefs.current[2] = el)} className={css.textContent}>
            <Field data={callToAction} className={ctaButtonClass} options={options} />
          </div>
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
