import React from 'react';

export const xIcon = () => {
  return (
    <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="22.5" cy="23" rx="22.5" ry="23" fill="#618741" />
      <path d="M31.9698 18.7329C31.4537 19.45 30.83 20.0754 30.1226 20.5852C30.1226 20.7725 30.1226 20.9598 30.1226 21.1575C30.1282 24.559 28.8239 27.8206 26.5019 30.2111C24.18 32.6016 21.0347 33.921 17.7711 33.8736C15.8844 33.8802 14.0218 33.4314 12.3293 32.5625C12.2381 32.5209 12.1792 32.4269 12.1796 32.3231V32.2087C12.1796 32.0592 12.2958 31.9381 12.4392 31.9381C14.2938 31.8744 16.0822 31.2046 17.5515 30.0234C15.8728 29.9882 14.3624 28.9523 13.6673 27.3595C13.6322 27.2725 13.6431 27.1727 13.6962 27.0962C13.7492 27.0197 13.8366 26.9775 13.9269 26.9849C14.4371 27.0383 14.9524 26.9888 15.4446 26.8392C13.5915 26.4385 12.1991 24.8359 11.9998 22.8745C11.9927 22.7804 12.0332 22.6893 12.1066 22.634C12.1801 22.5788 12.2758 22.5674 12.3593 22.604C12.8566 22.8325 13.3934 22.9529 13.9369 22.9578C12.3131 21.8476 11.6118 19.736 12.2295 17.8172C12.2932 17.6308 12.4464 17.493 12.6327 17.4546C12.8191 17.4162 13.011 17.4828 13.1381 17.6299C15.3293 20.0592 18.34 21.5067 21.5355 21.6674C21.4536 21.3272 21.4134 20.9776 21.4157 20.6268C21.4455 18.7877 22.538 17.1494 24.1815 16.4789C25.825 15.8083 27.6943 16.2383 28.9144 17.5675C29.746 17.4024 30.55 17.1115 31.3008 16.7038C31.3558 16.668 31.4255 16.668 31.4805 16.7038C31.5148 16.7611 31.5148 16.8338 31.4805 16.8911C31.1169 17.7585 30.5025 18.4861 29.7232 18.9723C30.4056 18.8898 31.0761 18.7221 31.7201 18.4728C31.7744 18.4343 31.8456 18.4343 31.8999 18.4728C31.9453 18.4944 31.9793 18.5358 31.9927 18.586C32.0062 18.6361 31.9978 18.6899 31.9698 18.7329Z" fill="white" />
    </svg>

  );
};
